<template>

<div class="cuadro_central mt-4 shadow">

  </div>

  <!--<div class=" d-flex justify-content-between">>
    <header class="jumbotron">
      <h3>
        <strong>{{currentUser.username}}</strong> Profile
      </h3>
    </header>
    <p>
      <strong>Id:</strong>
      {{currentUser.id}}
    </p>
    <p>
      <strong>Email:</strong>
      {{currentUser.email}}
    </p>
    <strong>Authorities:</strong>
    <ul>
      <li v-for="(role,index) in currentUser.roles" :key="index">{{role}}</li>
    </ul>
  </div>-->
</template>

<script>
export default {
  name: 'Profile',
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    }
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push('/login');
    }
  }
};

</script>

<style scoped>
.cuadro_central {
  background-color: #ffffff;
  padding: 20px 30px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 20px;
  -moz-box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
  -webkit-box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}
.nav-link{
  color: #AAAAAA;
   text-align: center;
}
.image-logo{
  text-align: center;
}
.nav{
    margin-top: 50px;
  width: 700px;
  text-align: center;
  margin-left:auto;
  margin-right:auto;
  text-align:center;
  color: #AAAAAA;
  line-height: 1.0em;
  text-align: justify;
  font-family: "Open Sans",sans-serif;
  overflow-wrap: break-word;
  font-weight: 300;
}
.parrafo{
  margin-top: 50px;
  width: 700px;
  text-align: center;
  margin-left:auto;
  margin-right:auto;
  text-align:center;
  color: #AAAAAA;
  line-height: 1.0em;
  text-align: justify;
  font-family: "Open Sans",sans-serif;
  overflow-wrap: break-word;
  font-weight: 300;
}
</style>